(function(f) {
  if (typeof exports === "object" && typeof module !== "undefined") {
    module.exports = f()
  } else if (typeof define === "function" && define.amd) {
    define([], f)
  } else {
    var g;
    if (typeof window !== "undefined") {
      g = window
    } else if (typeof global !== "undefined") {
      g = global
    } else if (typeof self !== "undefined") {
      g = self
    } else {
      g = this
    }
    g.finder = f()
  }
})(function() {
    var define, module, exports;
    return (function e(t, n, r) {
      function s(o, u) {
        if (!n[o]) {
          if (!t[o]) {
            var a = typeof require == "function" && require;
            if (!u && a) return a(o, !0);
            if (i) return i(o, !0);
            var f = new Error("Cannot find module '" + o + "'");
            throw f.code = "MODULE_NOT_FOUND", f
          }
          var l = n[o] = {
            exports: {}
          };
          t[o][0].call(l.exports, function(e) {
            var n = t[o][1][e];
            return s(n ? n : e)
          }, l, l.exports, e, t, n, r)
        }
        return n[o].exports
      }
      var i = typeof require == "function" && require;
      for (var o = 0; o < r.length; o++) s(r[o]);
      return s
    })({
      "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/index.js": [function(require, module, exports) {
      'use strict';

      var extend = require('xtend');
      var EventEmitter = require('eventemitter3');
      var isArray = require('x-is-array');

      var _ = require('./util');
      var defaults = {
      labelKey: 'label',
      kindKey: 'kind',
      childKey: 'children',
      className: {
        container: 'fjs-container',
        col: 'fjs-col',
        list: 'fjs-list',
        item: 'fjs-item',
        active: 'fjs-active',
        children: 'fjs-has-children',
        url: 'fjs-url',
        itemPrepend: 'fjs-item-prepend',
        itemContent: 'fjs-item-content',
        itemAppend: 'fjs-item-append',
        preview: 'preview-container',
        bookmarkEdit: 'preview-btns',
        bookmarkIcon: 'fa fa-bookmark button-icon',
        editIcon: 'fa fa-edit button-icon',
        editIconBtn: 'edit-btn',
        previewIconEco: 'fa fa-sitemap preview-icon',
        previewIconDevice: 'fa fa-microchip preview-icon',
        previewIconApp: 'fa fa-cogs preview-icon',
        previewIconPerson: 'fa fa-child preview-icon',
        previewIconUnknown: 'fa fa-question preview-icon',
        previewH1: 'preview-header',
        previewDescription: 'preview-description',
        previewKind: 'preview-kind',
        previewTags: 'preview-tags',
        previewParentTemplate: 'preview-parentTemplate',
        previewLifecycleState: 'preview-lifecycleState',
        previewEnabledState: 'preview-enabledState',
        togglePreview: 'toggle-preview-btn',
        deviceList: 'device-list'
      },
      labels: {
        description: 'Description',
        tags: 'Tags',
        parentTemplate: 'ID:',
        lifecycleState: 'Lifecycle State: ',
        enabledState: 'Enabled State: ',
        attributes: 'Attributes',
        events: 'Events',
        commands: 'Commands',
        kind: 'Kind'
      },
      descriptionKey: 'description',
      tagsKey: 'tags',
      parentTemplateKey: 'parentTemplate',
      lifecycleStateKey: 'lifecycleState',
      enabledStateKey: 'enabledState',
      previewDataKey: 'previewData',
      attributesDataKey: 'attributes',
      eventsDataKey: 'events',
      commandsDataKey: 'commands',
      deviceHealthStateKey: 'deviceHealthState',
      deviceHealthAlertCriteriaKey: 'deviceHealthAlertCriteria'
      };

      module.exports = finder;

      /**
       * @param  {element} container
       * @param  {Array|Function} data
       * @param  {object} options
       * @return {object} event emitter
       */
      function finder(container, data, options) {
        // console.log('\n\n\nfinder(data)\n',JSON.stringify(data,null,2));
        console.log('\n\n\nfinder(data)\n',data);
        var emitter = new EventEmitter();
        var cfg = extend(
          defaults,
          {
          container: container,
          emitter: emitter
          },
          options
        );

          // xtend doesn't deep merge
          cfg.className = extend(defaults.className, options ? options.className : {});

          // store the fn so we can call it on subsequent selections
          if (typeof data === 'function') {
            cfg.data = data;
          }

          // dom events
          container.addEventListener('click', finder.clickEvent.bind(null, cfg));
          container.addEventListener('keydown', finder.keydownEvent.bind(null, cfg));

          // internal events
          emitter.on('item-selected', finder.itemSelected.bind(null, cfg));
          emitter.on('create-column', finder.addColumn.bind(null, cfg));
          emitter.on('navigate', finder.navigate.bind(null, cfg));
          emitter.on('go-to', finder.goTo.bind(null, cfg, data));
          emitter.on('toggle-preview', finder.togglePreview.bind(null, cfg));
          emitter.on('edit-btn', finder.editBtnLink.bind(null,cfg));
          emitter.on('parent-template-link', finder.parentTemplateLink.bind(null,cfg));

          _.addClass(container, cfg.className.container);
          console.log('data[0].previewData',data[0].previewData);
          // OPTION B
          finder.createFirstColumn(data, cfg,parent, data[0].previewData,data[0].children);

          // OPTION A
          // finder.createColumn(data, cfg,parent, data[0].previewData,data[0].children);

          if (cfg.defaultPath) {
            window.requestAnimationFrame(function next() {
            finder.goTo(cfg, data, cfg.defaultPath);
            });
          }

          container.setAttribute('tabindex', 0);

          return emitter;
      }

      /**
       * @param {string} str
       * @return {string}
       */
      function trim(str) {
        return str.trim();
      }

      /**
       * @param  {object} config
       * @param {object} data
       * @param {array|string} path
       */
      finder.goTo = function goTo(cfg, data, goToPath) {
        var path = isArray(goToPath)
          ? goToPath
          : goToPath
            .split('/')
            .map(trim)
            .filter(Boolean);
        if (path.length) {
          while (cfg.container.firstChild) {
          cfg.container.removeChild(cfg.container.firstChild);
          }
          finder.selectPath(path, cfg, data);
        }
      };

      /**
       * @param {element} container
       * @param {element} column to append to container
       */
      finder.addColumn = function addColumn(cfg, col) {
        console.group('\n\naddColumn()');
        console.log('cfg = ',cfg);
        console.log('col = ',col);
        console.groupEnd();
        cfg.container.appendChild(col);

        cfg.emitter.emit('column-created', col);
      };

      /**
       * @param  {object} config
       * @param  {object} event value
       * @param {object | undefined}
       */
      finder.itemSelected = function itemSelected(cfg, value) {
        // console.log('\n\n\n itemSelected(value)', value);  

        var itemEl = value.item;
        // console.log('\nitemSelected cfg',cfg);
        var item = itemEl._item;
        // console.log('itemSelected value.col',value.col);
        var col = value.col;
        var data = item[cfg.childKey] || cfg.data;
        // var data = item
        console.log('\n\n\n itemSelected(item.children)',item.children);  
        console.log('\n\n\n itemSelected(item)',item);  
        console.log('\n\n\n itemSelected(data)',data);  
        var activeEls = col.getElementsByClassName(cfg.className.active);
        var x = window.pageXOffset;
        var y = window.pageYOffset;
        var newCol;

        var previewData = item[cfg.previewDataKey] || cfg.data;
        // console.log('\n\n\n itemSelected(previewData)', previewData);
        // var thingType = item[cfg.kindKey] || cfg.data;
        var thingType = previewData[0].kind;

        if (activeEls.length) {
          _.removeClass(activeEls[0], cfg.className.active);
        }
        _.addClass(itemEl, cfg.className.active);
        _.nextSiblings(col).map(_.remove);

        // fix for #14: we need to keep the focus on a live DOM element, such as the
        // container, in order for keydown events to get fired
        cfg.container.focus();
        window.scrollTo(x, y);
        console.log('window.scrollTo(x, y)\nx='+x+'\ny='+y);
        console.log('window',window);
        console.log('container',container);

        if (data) {
            // creates new column if children exists
          // console.log('\n\n\n itemSelected if(children)',data);
          // console.log('\n\n\n itemSelected if(item)',item);
          // console.log('\n\n\n itemSelected if(data) - previewData',data[0].previewData);
          // console.log('\n\n\n itemSelected if(data) - thingType',thingType);
          newCol = finder.createColumn(data, cfg, item, previewData,item.children);
          cfg.emitter.emit('interior-selected', item);

        
        } else 
        if (item.url) {
          document.location.href = item.url;
        } else
        if (thingType === 'device') {
          cfg.emitter.emit('leaf-selected', item);

          console.log('itemSelected (entity type)', thingType);
          console.log('\n\ndevice previewData\n',previewData);

          if (previewData[0].attributes != undefined||previewData[0].attributes != null){
            var attributesData = previewData[0].attributes || cfg.data;
          }

          if (previewData[0].events != undefined||previewData[0].events != null){
            var eventsData = previewData[0].events || cfg.data;
          }

          if (previewData[0].commands != undefined||previewData[0].commands != null){
            var commandsData = previewData[0].commands || cfg.data;
          }

          // console.group('\n\n----entity selected: ', thingType);
          // console.table(previewData);
          // console.log('attributesData', attributesData);
          // console.log('eventsData', eventsData);
          // console.log('commandsData', commandsData);
          // console.log('deviceHealthStateData', deviceHealthStateData);
          // console.log('deviceHealthAlertCriteria', deviceHealthAlertCriteria);
          // console.groupEnd();
          newCol = finder.createEndColumn(previewData, cfg, item);
          cfg.emitter.emit('interior-selected', item);
        } else
        if (thingType === 'application'){
          cfg.emitter.emit('leaf-selected', item);

          console.log('itemSelected (entity type)', thingType);
          console.group('\n\n----entity selected: ', thingType);
          console.log('previewData', previewData);
          console.groupEnd();
          newCol = finder.createEndColumn(previewData, cfg, item);
          cfg.emitter.emit('interior-selected', item);
        } else
        if(thingType === 'person'){
          cfg.emitter.emit('leaf-selected', item);
          console.log('itemSelected (entity type)', thingType);
          console.log('previewData', previewData);
          newCol = finder.createEndColumn(previewData, cfg, item);
          cfg.emitter.emit('interior-selected', item);
        } 
        else
        if(thingType === 'ecosystem'){
          cfg.emitter.emit('leaf-selected', item);
          console.log('itemSelected (entity type)', thingType);
          console.log('previewData', previewData);
          newCol = finder.createEndColumn(previewData, cfg, item);
          cfg.emitter.emit('interior-selected', item);
        }

        return newCol;
      };

      /**
       * Click event handler for whole container
       * @param  {element} container
       * @param  {object} config
       * @param  {object} event
       */
      finder.clickEvent = function clickEvent(cfg, event) {
        console.log('clickEvent(event)',event);
        var el = event.target;
        var col = _.closest(el, function test(el) {
          return _.hasClass(el, cfg.className.col);
        });
        var item = _.closest(el, function test(el) {
          return _.hasClass(el, cfg.className.item);
        });
        var showHide = _.closest(el, function test(el) {
          return _.hasClass(el, cfg.className.togglePreview);
        });
        var editBtnLink = _.closest(el, function test(el) {
          return _.hasClass(el, cfg.className.editIconBtn);
        })
        var parentTemplateLink = _.closest(el, function test(el) {
          return _.hasClass(el, 'parent-template-link');
        })

        _.stop(event);

        // list item clicked
        if (item) {
          cfg.emitter.emit('item-selected', {
            col: col,
            item: item
          });
        }

        // toggle prview
        if(showHide) {
          var theEcoDiv = showHide.previousSibling;
          var theEcoTitle = theEcoDiv.children[2].title;
          // console.log('showHide(showHide)',showHide);
          // console.log('showHide(theEcoDiv)',theEcoDiv);
          // console.log('showHide(theEcoTitle)',theEcoTitle);
          cfg.emitter.emit('toggle-preview', {
            showHide: showHide,
            label: el,
            ecoName: theEcoTitle
          })
        }

        if(editBtnLink) {
          console.log('editBtnLink el.getAttribute(\'href\')',el.getAttribute('href'));
          cfg.emitter.emit('edit-btn', {
            href: el.getAttribute('href')
          })
        }

        if(parentTemplateLink) {
          console.log('parentTemplateLink el',el);
          cfg.emitter.emit('parent-template-link', {
            href:el.getAttribute('href')
          })
        }
      };

      finder.parentTemplateLink = function parentTemplateLink(cfg,value){
        console.log('parentTemplateLink value',value);
        var url = value.href;
        // IOT-6609
        window.location.href = url;
        window.location.reload(true);
      }
      finder.editBtnLink = function editBtnLink(cfg,value){
        console.log('value',value);
        var url = '/'+value.href.replace(/^[a-z]{4,5}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/, '$1');
        console.log('\n\n finder.editBtnLink \n\n url',url);
        location.href = url;
      };

      finder.togglePreview = function togglePreview(cfg, value){
        // console.log('togglePreview(value)', value);
        // console.log('togglePreview(value.ecoName)', value.ecoName);


        var itemEl = value.showHide;
        var btnLabel = value.label;
        var item = itemEl.previousSibling;
        var itemVisibility = item;

        if(item.style.display === "block"){
          // console.log('display: block',item.style.display);
          btnLabel.innerHTML = '<i class="fa fa-chevron-down">&nbsp;</i> SHOW <i class="fa fa-chevron-down">&nbsp;</i><p>'+ value.ecoName +'</p>';
          item.style.display = "none";
        } else{
          // console.log('display: none',item.style.display);
          btnLabel.innerHTML = '<i class="fa fa-chevron-up">&nbsp;</i> HIDE <i class="fa fa-chevron-up">&nbsp;</i>';
          item.style.display = "block";
        }
      };

      /**
       * Keydown event handler for container
       * @param  {object} config
       * @param  {object} event
       */
      finder.keydownEvent = function keydownEvent(cfg, event) {
        var arrowCodes = {
          38: 'up',
          39: 'right',
          40: 'down',
          37: 'left'
        };

        if (event.keyCode in arrowCodes) {
          _.stop(event);

          cfg.emitter.emit('navigate', {
          direction: arrowCodes[event.keyCode],
          container: cfg.container
          });
        }
      };
      /**
       * Function to handle preselected path from option.
       * This is an recurive function which passes data of child
       * to itself for rendering column.
       * @param {array} path
       * @param {object} cfg
       * @param {object} data
       * @param {object | undefined} column
       */
      finder.selectPath = function selectPath(path, cfg, data, column) {
        var currPath = path[0];
        var childData = data.find(function find(item) {
          return item[cfg.labelKey] === currPath;
        });

        var col = column || finder.createColumn(data, cfg);
        var newCol = finder.itemSelected(cfg, {
          col: col,
          item: _.first(col, '[data-fjs-item="' + currPath + '"]')
        });
        path.shift();
        if (path.length) {
          finder.selectPath(path, cfg, childData[cfg.childKey], newCol);
        }
      };
      /**
       * Navigate the finder up, down, right, or left
       * @param  {object} config
       * @param  {object} event value - `container` prop contains a reference to the
       * container, and `direction` can be 'up', 'down', 'right', 'left'
       */
      finder.navigate = function navigate(cfg, value) {
        var active = finder.findLastActive(cfg);
        var target = null;
        var dir = value.direction;
        var item;
        var col;

        if (active) {
          item = active.item;
          col = active.col;

          if (dir === 'up' && item.previousSibling) {
            target = item.previousSibling;
          } else if (dir === 'down' && item.nextSibling) {
            target = item.nextSibling;
          } else if (dir === 'right' && col.nextSibling) {
            col = col.nextSibling;
            target = _.first(col, '.' + cfg.className.item);
          } else if (dir === 'left' && col.previousSibling) {
            col = col.previousSibling;
            target =
              _.first(col, '.' + cfg.className.active) ||
              _.first(col, '.' + cfg.className.item);
          }
        } else {
          col = _.first(cfg.container, '.' + cfg.className.col);
          target = _.first(col, '.' + cfg.className.item);
        }

        if (target) {
          cfg.emitter.emit('item-selected', {
          container: cfg.container,
          col: col,
          item: target
          });
        }
      };

      /**
       * Find last (right-most) active item and column
       * @param  {Element} container
       * @param  {Object} config
       * @return {Object}
       */
      finder.findLastActive = function findLastActive(cfg) {
        var activeItems = cfg.container.getElementsByClassName(cfg.className.active);
        var item;
        var col;

        if (!activeItems.length) {
          return null;
        }

        item = activeItems[activeItems.length - 1];
        col = _.closest(item, function test(el) {
          return _.hasClass(el, cfg.className.col);
        });

        return {
          col: col,
          item: item
        };
      };

      /**
       * @param  {object} data
       * @param  {object} config
       * @param  {parent} [parent] - parent item that clicked/triggered createColumn
       */
      
      finder.createFirstColumn = function createFirstColumn(data, cfg, parent, previewData,children) {
        console.log('createFirstColumn(data)',data);
        console.log('createFirstColumn(previewData)',previewData);
        console.log('createFirstColumn(children)',children);
        var div;
        var previewDiv;
        var list;
        
        function callback(data) {
          return finder.createFirstColumn(data, cfg, parent);
        }

        if (typeof data === 'function') {
          data.call(null, parent, cfg, callback);
        } else if (isArray(data)) {
          // creates column when data array present
          console.log('createFirstColumn(data[0].children)',data[0].children);
          console.log('createFirstColumn(previewData)',previewData);
         
          div = _.el('div');

          if(previewData != null){
            // creates preview div when data array present
            console.log('creating previewData');
            previewDiv = finder.createPreview(previewData,cfg);
            div.appendChild(previewDiv);
            var button = _.el('button.' + cfg.className.togglePreview);

            button.innerHTML = '<i class="fa fa-chevron-up">&nbsp;</i> HIDE <i class="fa fa-chevron-up">&nbsp;</i>';
            button.setAttribute('id', 'toggle-preview-btn');

            div.appendChild(button);
            
          }
          var list;
          list = finder.createList(children, cfg);
          div.appendChild(list);
          _.addClass(div, cfg.className.col);
          cfg.emitter.emit('create-column', div);
          return div;
        } else {
          throw new Error('Unknown data type');
        }
      };
      
      finder.createColumn = function createColumn(data, cfg, parent, previewData,children) {
        console.log('\n\n\n createColumn(data)',data);
        console.log('createColumn(previewData)',previewData);
        console.log('createColumn(children[0].children)',children[0].children);
        var div;
        var previewDiv;
        var list;
        
        function callback(data) {
          return finder.createColumn(data, cfg, parent);
        }

        if (typeof data === 'function') {
          data.call(null, parent, cfg, callback);
        } else if (isArray(data)) {
          // creates column when data array present
          console.log('\n\n\n createColumn(data)',data);
          console.log('createColumn(children)',children);
          console.log('createColumn(previewData)',previewData);
         
          
          div = _.el('div');
          if(previewData != null){
          // creates preview div when data array present
          // console.log('createColumn(previewData)',previewData);
          // console.log('creating previewData');
          previewDiv = finder.createPreview(previewData,cfg);
          div.appendChild(previewDiv);
          var button = _.el('button.' + cfg.className.togglePreview);

          button.innerHTML = '<i class="fa fa-chevron-up">&nbsp;</i> HIDE <i class="fa fa-chevron-up">&nbsp;</i>';
          button.setAttribute('id', 'toggle-preview-btn');

          div.appendChild(button);
          
          }
          // OPTION B
          list = finder.createList(children[0].children, cfg);

          // OPTION A
          // list = finder.createList(children, cfg);

          console.log('\n\n\n list', list);
          div.appendChild(list);
          _.addClass(div, cfg.className.col);
          cfg.emitter.emit('create-column', div);
          return div;
        } else {
          throw new Error('Unknown data type');
        }
      };

      finder.createEndColumn = function createEndColumn(previewData, cfg, parent) {


        function callback(previewData) {
          return finder.createEndColumn(previewData, cfg, parent);
        }

        if (typeof previewData === 'function') {
          // console.log('typeof previewData');
          previewData.call(null, parent, cfg, callback);
        } else 
        if (isArray(previewData)) {

          var div;
          var list;

          // console.trace('\n\n\n\nfinder.createEndColumn(thingType): ',previewData[0].kind);

          var thingType = previewData[0].kind || cfg.data;
          if (thingType === 'device'){

            console.log('\n\n\nshow DEVICE previewData\n',previewData);
            var attributes;
            var events;
            var commands;
            
            if (previewData[0].attributes != undefined||previewData[0].attributes != null){
              // console.log('@@createEndColumn(attributesData)',attributesData);
              var attributesData = previewData[0].attributes || cfg.data;
              attributes = finder.createEndList(thingType, attributesData, cfg);
            }
            
            if (previewData[0].events != undefined||previewData[0].events != null){
              // console.log('@@createEndColumn(eventsData)',eventsData);
              var eventsData = previewData[0].events || cfg.data;
              events = finder.createEndList(thingType, eventsData, cfg);
            }

            if (previewData[0].commands != undefined||previewData[0].commands != null){
              // console.log('@@createEndColumn(commandsData)',commandsData);
              var commandsData = previewData[0].commands || cfg.data;
              commands = finder.createEndList(thingType, commandsData, cfg);
            }

            list = finder.createDetailCol(previewData, cfg);
            
            
            div = _.el('div');

            var attributesLabel = _.el('p.' + cfg.className.deviceList);
            attributesLabel.appendChild(document.createTextNode(cfg.labels.attributes));
            var eventsLabel = _.el('p.' + cfg.className.deviceList);
            eventsLabel.appendChild(document.createTextNode(cfg.labels.events));
            var commandsLabel = _.el('p.' + cfg.className.deviceList);
            commandsLabel.appendChild(document.createTextNode(cfg.labels.commands));
            // console.group('\n\n@@createEndColumn(data,cfg,parent,attributesData,)eventsData,commandsData');
            // console.log('@@createEndColumn(previewData)',previewData);
            // console.log('@@createEndColumn(cfg)',cfg);
            // console.log('@@createEndColumn(parent)',parent);
            // console.log('@@createEndColumn(attributesData)',attributesData);
            // console.log('@@createEndColumn(eventsData)',eventsData);
            // console.log('@@createEndColumn(commandsData)',commandsData);
            // console.log('@@createEndColumn(list)',list);
            // console.groupEnd();

            div.appendChild(list);
            if (previewData[0].attributes != undefined||previewData[0].attributes != null){
              div.appendChild(attributesLabel);
              div.appendChild(attributes);
            }
            if (previewData[0].events != undefined||previewData[0].events != null){
              div.appendChild(eventsLabel);
              div.appendChild(events);
            }
            if (previewData[0].commands != undefined||previewData[0].commands != null){
              div.appendChild(commandsLabel);
              div.appendChild(commands);
            }
            
            _.addClass(div, cfg.className.col);
            cfg.emitter.emit('create-column', div);
            return div;
          } else

          if (thingType === 'application'){
            console.log('show APPLICATION data');
            var attributes;

            // var attributesData = previewData[1].attributes || cfg.data;
            // var eventsData = previewData[2].events || cfg.data;
            // var commandsData = previewData[3].commands || cfg.data;

            list = finder.createDetailCol(previewData, cfg);
            // attributes = finder.createEndList(thingType, attributesData, cfg);
            div = _.el('div');

            console.group('\n\n@@createEndColumn(data,cfg,parent,attributesData,)eventsData,commandsData');
            console.log('@@createEndColumn(previewData)',previewData);
            console.log('@@createEndColumn(cfg)',cfg);
            console.log('@@createEndColumn(parent)',parent);
            console.log('@@createEndColumn(list)',list);
            console.groupEnd();

            div.appendChild(list);
            // div.appendChild(attributes);
            _.addClass(div, cfg.className.col);
            cfg.emitter.emit('create-column', div);
            return div;
          } else

          if (thingType === 'person'){
            console.log('show PERSON data');
            list = finder.createDetailCol(previewData, cfg);
            div = _.el('div');

            console.group('\n\n@@createEndColumn(data,cfg,parent,attributesData,)eventsData,commandsData');
            console.log('@@createEndColumn(previewData)',previewData);
            console.log('@@createEndColumn(cfg)',cfg);
            console.log('@@createEndColumn(parent)',parent);
            console.log('@@createEndColumn(list)',list);
            console.groupEnd();

            div.appendChild(list);
            // div.appendChild(attributes);
            _.addClass(div, cfg.className.col);
            cfg.emitter.emit('create-column', div);
            return div;
          } else

          if (thingType === 'ecosystem') {
            console.log('show ECOSYSTEM data');
            list = finder.createDetailCol(previewData, cfg);
            div = _.el('div');

            console.group('\n\n@@createEndColumn(data,cfg,parent,attributesData,)eventsData,commandsData');
            console.log('@@createEndColumn(previewData)',previewData);
            console.log('@@createEndColumn(cfg)',cfg);
            console.log('@@createEndColumn(parent)',parent);
            console.log('@@createEndColumn(list)',list);
            console.groupEnd();

            div.appendChild(list);
            // div.appendChild(attributes);
            _.addClass(div, cfg.className.col);
            cfg.emitter.emit('create-column', div);
            return div;
          }
          
        } else {
          throw new Error('Unknown data type');
        }
      };


      /**
      * @param  {array} data
      * @param  {object} config
      * @return {element} list
      */
      finder.createPreview = function createPreview(previewData, cfg) {
        var previewData = previewData[0];
        console.log('\n\n createPreview(previewData)',previewData);

        var previewDiv;
        var previewContainer = _.el('div.' + cfg.className.preview);
        previewContainer.setAttribute('id', 'previewContainer');
        previewContainer.setAttribute('style','display:block');
        previewDiv = finder.createPreviewItem(previewData,cfg);
        previewContainer.appendChild(previewDiv);
        
        return previewContainer;
      };

      finder.createDetailCol = function createDetailCol(previewData, cfg) {
        var previewData = previewData[0];
        var thingType = previewData.kind;
        console.group('\n\ncreateDetailCol()');
        console.table(previewData,'\n\n');
        console.log('thingType:', thingType);
        console.log('\n\n');
        console.groupEnd();

        var previewDiv;
        var previewContainer = _.el('div.');

        previewContainer.setAttribute('id', 'detailCol');
        if(thingType === 'application'){
          previewContainer.setAttribute('class', 'app-column');
        }else
        if(thingType === 'device'){
          previewContainer.setAttribute('class','device-column');
        }
        if(thingType === 'person'){
          previewContainer.setAttribute('class','person-column');
        }
        if(thingType === 'group'){
          previewContainer.setAttribute('class','group-column');
        }
        previewDiv = finder.createPreviewItem(previewData,cfg);
        previewContainer.appendChild(previewDiv);
        
        return previewContainer;
      };

      finder.createPreviewContent = function createPreviewContent(previewData, cfg){
        var previewData = previewData[0];
      }

      finder.createList = function createList(data, cfg) {
        var ul = _.el('ul');
        var items = data.map(function create(item) {
          // creates entity list
          // console.log('\n\n\n* createList(data)',data)
          return finder.createItem(cfg, item);
        });
        var docFrag;

        docFrag = items.reduce(function each(docFrag, curr) {
          docFrag.appendChild(curr);
          return docFrag;
        }, document.createDocumentFragment());

        ul.appendChild(docFrag);
      
        _.addClass(ul, cfg.className.list);
        
        return ul;
      };

      finder.createEndList = function createEndList(thingType, data, cfg) {
        console.log('createEndList(data)',data);
        if(thingType==='device'){
          var ul = _.el('ul.' + cfg.className.deviceList);
        } else {
          var ul = _.el('ul');
        }
      
        console.group('\n\n\n****',thingType);
        console.log('items',items);
        console.log('data',data);
        console.groupEnd();
        var items = data.map(function create(item) {
          // creates entity list
          // console.log('\n\n\n** createEndList(item)',item);
          // console.log('\n\n\n** createEndList(cfg)',cfg);
          return finder.createEndItem(cfg, item);
        });
        var docFrag;

        docFrag = items.reduce(function each(docFrag, curr) {
          docFrag.appendChild(curr);
          // console.log('\n\n\ncreateEndList(curr)',curr);
          return docFrag;
        }, document.createDocumentFragment());

        ul.appendChild(docFrag);
      
        // _.addClass(ul, cfg.className.list);
        console.log('createEndList ul',ul);
        return ul;
      };

      /**
      * Default item render fn
      * @param  {object} cfg config object
      * @param  {object} item data
      * @return {DocumentFragment}
      */

      /** 
      * copy of createItemContent
      * display content for thing detail column
      */
      finder.createPreviewItemContent = function createPreviewItemContent(previewData, cfg) {
        console.log('createPreviewItemContent(previewData)',previewData);
        console.log('createPreviewItemContent(previewData.id)',previewData.id);
       

        var frag = document.createDocumentFragment();
        var thingType = previewData.kind;

        if(thingType==='person'){
          console.log('thingType===person');
          console.log('\n\n\n previewData',previewData);
  
          var previewIcon = _.el('i.' + cfg.className.previewIconPerson);
          // var editLink = _.el('a.' + cfg.className.editIconBtn);
          var bookmarkEditBtns = _.el('div.' + cfg.className.bookmarkEdit);
          // var bookmarkIcon = _.el('i.' + cfg.className.bookmarkIcon);
          // var editIcon = _.el('i.' + cfg.className.editIcon);
  
          // // set value for edit btn href
          // var url = previewData.id;
          // if (thingType === 'ecosystem'||thingType === 'group') {
          //   editLink.href = '/#/ecosystems/' + url;
          // } else
          // if (thingType === 'device') {
          //   editLink.href = '/#/devices?id=' + url;
          // } else
          // if (thingType === 'application') {
          //   editLink.href = '/#/applications/' + url;
          // }

          // commented out until we can link to a user page
          // editIcon.setAttribute('href',editLink);
          // editLink.appendChild(editIcon);
          // bookmarkEditBtns.appendChild(editLink);
          // bookmarkEditBtns.appendChild(bookmarkIcon);
  
          // create title
          var previewH1 = _.el('h1.' + cfg.className.previewH1);
          // previewH1.appendChild(document.createTextNode(previewData.parent));
          previewH1.innerHTML = previewData.parent + '<span>' + thingType + '</span>';
          previewH1.setAttribute('title',previewData.parent);
          
          var previewName = _.el('dl.' + cfg.className.previewDescription);
          var nameFrag = document.createDocumentFragment();
          var nameLabel = document.createElement('dt');
          var nameValue = document.createElement('dd');
          nameLabel.textContent = 'Name';
          nameValue.textContent = previewData.name.given;
          nameFrag.appendChild(nameLabel);
          nameFrag.appendChild(nameValue);
          previewName.appendChild(nameFrag);

          var previewEmail = _.el('dl.' + cfg.className.previewDescription);
          var emailFrag = document.createDocumentFragment();
          var emailLabel = document.createElement('dt');
          var emailValue = document.createElement('dd');
          emailLabel.textContent = 'Email';
          emailValue.textContent = previewData.email;
          emailFrag.appendChild(emailLabel);
          emailFrag.appendChild(emailValue);
          previewEmail.appendChild(emailFrag);

          var previewPhone = _.el('dl.' + cfg.className.previewDescription);
          var phoneFrag = document.createDocumentFragment();
          var phoneLabel = document.createElement('dt');
          var phoneValue = document.createElement('dd');
          phoneLabel.textContent = 'Phone';
          phoneValue.textContent = previewData.phoneNumber[0].number;
          phoneFrag.appendChild(phoneLabel);
          phoneFrag.appendChild(phoneValue);
          previewPhone.appendChild(phoneFrag);

          frag.appendChild(bookmarkEditBtns);
          frag.appendChild(previewIcon);
          frag.appendChild(previewH1);
          // frag.appendChild(previewKind);
          frag.appendChild(previewName);
          frag.appendChild(previewEmail);
          frag.appendChild(previewPhone);

        }
        if(thingType==='ecosystem'||thingType==='group'){
          console.log('\n\n createPreviewItemContent\n\n thingType===ecosystem!');

          var previewIcon = _.el('i.' + cfg.className.previewIconEco);
          
          var editLink = _.el('a.' + cfg.className.editIconBtn);
          var bookmarkEditBtns = _.el('div.' + cfg.className.bookmarkEdit);
          var bookmarkIcon = _.el('i.' + cfg.className.bookmarkIcon);
          var editIcon = _.el('i.' + cfg.className.editIcon);
  
          // set value for edit btn href
          var url = previewData.id;
          if (thingType === 'ecosystem'||thingType === 'group') {
            editLink.href = '/#/ecosystems/' + url;
          //  window.location.reload();
          } else
          if (thingType === 'device') {
            editLink.href = '/#/devices/' + url;
          } else
          if (thingType === 'application') {
            editLink.href = '/#/applications/' + url;
          }
          var tempLink = editLink.href.replace(/^.*\/\/[^\/]+/, '').replace(/^\/|\/$/g, '');
          
          editIcon.setAttribute('href',tempLink);
          console.log('\n\n\n tempLink', tempLink);
          editLink.appendChild(editIcon);
          console.log('\n\n\neditIcon appended', editIcon);
          bookmarkEditBtns.appendChild(editLink);
          // bookmarkEditBtns.appendChild(bookmarkIcon);
  
          // create title
          var previewH1 = _.el('h1.' + cfg.className.previewH1);
          // previewH1.appendChild(document.createTextNode(previewData.parent));
          
          previewH1.innerHTML = previewData.parent + '<span>' + thingType + '</span>';
          previewH1.setAttribute('title',previewData.parent);

          var previewDescription = _.el('dl.' + cfg.className.previewDescription);
          var descFrag = document.createDocumentFragment();
          var dt = document.createElement('dt');
          var dd = document.createElement('dd');
          dt.textContent = cfg.labels.description;
          dd.textContent = previewData.description;
          descFrag.appendChild(dt);
          descFrag.appendChild(dd);
          previewDescription.appendChild(descFrag);

          frag.appendChild(bookmarkEditBtns);
          frag.appendChild(previewIcon);
          frag.appendChild(previewH1);
          // frag.appendChild(previewKind);
          frag.appendChild(previewDescription);

          var previewTags = _.el('dl.' + cfg.className.previewTags);
          var tags = document.createDocumentFragment();
          var dtTags = document.createElement('dt');
          var ddTags = document.createElement('dd');
          dtTags.textContent =cfg.labels.tags;
          ddTags.textContent = previewData.tags;
          tags.appendChild(dtTags);
          tags.appendChild(ddTags);
          previewTags.appendChild(tags);

          
          var previewParentTemplate = _.el('dl.' + cfg.className.previewParentTemplate);
          var parentTemplateFrag = document.createDocumentFragment();
          var parentTemplateLabel = document.createElement('dt');
          var parentTemplateName = document.createElement('dd');
          var parentTemplateLink = document.createElement('a');

          parentTemplateLink.setAttribute('href', '/#/ecosystems/'+previewData.id);
          parentTemplateLink.setAttribute('class', 'parent-template-link');
          parentTemplateName.setAttribute('href', '/#/ecosystems/'+previewData.id);

          parentTemplateLabel.textContent = cfg.labels.parentTemplate;
          parentTemplateName.textContent = previewData.id;
          parentTemplateFrag.appendChild(parentTemplateLabel);
          parentTemplateFrag.appendChild(parentTemplateLink);
          parentTemplateLink.appendChild(parentTemplateName);
          previewParentTemplate.appendChild(parentTemplateFrag);
      
          var previewLifecycleState = _.el('p.' + cfg.className.previewLifecycleState);
          previewLifecycleState.textContent = cfg.labels.lifecycleState + previewData.lifecycleState;

          var previewEnabledState = _.el('p.' + cfg.className.previewEnabledState);
          previewEnabledState.textContent = cfg.labels.enabledState + previewData.enabledState;

          frag.appendChild(previewTags);
          frag.appendChild(previewParentTemplate); 
          frag.appendChild(previewLifecycleState);
          frag.appendChild(previewEnabledState);
        }
        if(thingType==='device'){
          console.log('thingType===device');
  
          var previewIcon = _.el('i.' + cfg.className.previewIconDevice);
          var editLink = _.el('a.' + cfg.className.editIconBtn);
          var bookmarkEditBtns = _.el('div.' + cfg.className.bookmarkEdit);
          var bookmarkIcon = _.el('i.' + cfg.className.bookmarkIcon);
          var editIcon = _.el('i.' + cfg.className.editIcon);
  
          // set value for edit btn href
          var url = previewData.id;
          if (thingType === 'ecosystem'||thingType === 'group') {
            editLink.href = '/#/ecosystems/' + url;
        //    window.location.reload();
          } else
          if (thingType === 'device') {
            editLink.href = '/#/devices/' + url;
          } else
          if (thingType === 'application') {
            editLink.href = '/#/applications/' + url;
          }

          var tempLink = editLink.href.replace(/^.*\/\/[^\/]+/, '').replace(/^\/|\/$/g, '');
          editIcon.setAttribute('href',tempLink);
          editLink.appendChild(editIcon);
          bookmarkEditBtns.appendChild(editLink);
          // bookmarkEditBtns.appendChild(bookmarkIcon);
  
          // create title
          var previewH1 = _.el('h1.' + cfg.className.previewH1);
          // previewH1.appendChild(document.createTextNode(previewData.parent));
          
          previewH1.innerHTML = previewData.parent + '<span>' + thingType + '</span>';
          previewH1.setAttribute('title',previewData.parent);

          var previewDescription = _.el('dl.' + cfg.className.previewDescription);
          var descFrag = document.createDocumentFragment();
          var dt = document.createElement('dt');
          var dd = document.createElement('dd');
          dt.textContent = cfg.labels.description;
          dd.textContent = previewData.description;
          descFrag.appendChild(dt);
          descFrag.appendChild(dd);
          previewDescription.appendChild(descFrag);


          frag.appendChild(bookmarkEditBtns);
          frag.appendChild(previewIcon);
          frag.appendChild(previewH1);
          // frag.appendChild(previewKind);

          frag.appendChild(previewDescription);

          console.log('not app or person');
          console.log('thingType',thingType);
          console.log('\n\n\n previewData',previewData);
          var previewTags = _.el('dl.' + cfg.className.previewTags);
          var tags = document.createDocumentFragment();
          var dtTags = document.createElement('dt');
          var ddTags = document.createElement('dd');
          dtTags.textContent =cfg.labels.tags;
          ddTags.textContent = previewData.tags;
          tags.appendChild(dtTags);
          tags.appendChild(ddTags);
          previewTags.appendChild(tags);

          
          var previewParentTemplate = _.el('dl.' + cfg.className.previewParentTemplate);
          var parentTemplateFrag = document.createDocumentFragment();
          var parentTemplateLabel = document.createElement('dt');
          var parentTemplateName = document.createElement('dd');
          var parentTemplateLink = document.createElement('a');



          parentTemplateLink.setAttribute('href', '/#/devices/'+previewData.id);
          parentTemplateLink.setAttribute('class', 'parent-template-link');
          parentTemplateName.setAttribute('href', '/#/devices/'+previewData.id);

          parentTemplateLabel.textContent = cfg.labels.parentTemplate;
          parentTemplateName.textContent = previewData.id;
          parentTemplateFrag.appendChild(parentTemplateLabel);
          parentTemplateFrag.appendChild(parentTemplateLink);
          parentTemplateLink.appendChild(parentTemplateName);
          previewParentTemplate.appendChild(parentTemplateFrag);
      
          var previewLifecycleState = _.el('p.' + cfg.className.previewLifecycleState);
          previewLifecycleState.textContent = cfg.labels.lifecycleState + previewData.lifecycleState;

          var previewEnabledState = _.el('p.' + cfg.className.previewEnabledState);
          previewEnabledState.textContent = cfg.labels.enabledState + previewData.enabledState;

          frag.appendChild(previewTags);
          frag.appendChild(previewParentTemplate); 
          frag.appendChild(previewLifecycleState);
          frag.appendChild(previewEnabledState);
            

          // return frag;
        }
        if(thingType==='application'){
          console.log('thingType===application');
  

          var previewIcon = _.el('i.' + cfg.className.previewIconApp);
            
          var editLink = _.el('a.' + cfg.className.editIconBtn);
          var bookmarkEditBtns = _.el('div.' + cfg.className.bookmarkEdit);
          var bookmarkIcon = _.el('i.' + cfg.className.bookmarkIcon);
          var editIcon = _.el('i.' + cfg.className.editIcon);
  
          // set value for edit btn href
          var url = previewData.id;
          if (thingType === 'ecosystem'||thingType === 'group') {
            editLink.href = '/#/ecosystems/' + url;
        //    window.location.reload();
          } else
          if (thingType === 'device') {
            editLink.href = '/#/devices/' + url;
          } else
          if (thingType === 'application') {
            editLink.href = '/#/applications/' + url;
          }

          var tempLink = editLink.href.replace(/^.*\/\/[^\/]+/, '').replace(/^\/|\/$/g, '');
          editIcon.setAttribute('href',tempLink);
          editLink.appendChild(editIcon);
          bookmarkEditBtns.appendChild(editLink);
          // bookmarkEditBtns.appendChild(bookmarkIcon);
  
          // create title
          var previewH1 = _.el('h1.' + cfg.className.previewH1);
          // previewH1.appendChild(document.createTextNode(previewData.parent));
          
          previewH1.innerHTML = previewData.parent + '<span>' + thingType + '</span>';
          previewH1.setAttribute('title',previewData.parent);

          var previewDescription = _.el('dl.' + cfg.className.previewDescription);
          var descFrag = document.createDocumentFragment();
          var dt = document.createElement('dt');
          var dd = document.createElement('dd');
          dt.textContent = cfg.labels.description;
          dd.textContent = previewData.description;
          descFrag.appendChild(dt);
          descFrag.appendChild(dd);
          previewDescription.appendChild(descFrag);


          frag.appendChild(bookmarkEditBtns);
          frag.appendChild(previewIcon);
          frag.appendChild(previewH1);
          // frag.appendChild(previewKind);

          frag.appendChild(previewDescription);

          console.log('not app or person');
          console.log('thingType',thingType);
          console.log('\n\n\n previewData',previewData);
          var previewTags = _.el('dl.' + cfg.className.previewTags);
          var tags = document.createDocumentFragment();
          var dtTags = document.createElement('dt');
          var ddTags = document.createElement('dd');
          dtTags.textContent =cfg.labels.tags;
          ddTags.textContent = previewData.tags;
          tags.appendChild(dtTags);
          tags.appendChild(ddTags);
          previewTags.appendChild(tags);

          
          var previewParentTemplate = _.el('dl.' + cfg.className.previewParentTemplate);
          var parentTemplateFrag = document.createDocumentFragment();
          var parentTemplateLabel = document.createElement('dt');
          var parentTemplateName = document.createElement('dd');
          var parentTemplateLink = document.createElement('a');


          parentTemplateLink.setAttribute('href', '/#/applications/'+previewData.id);
          parentTemplateLink.setAttribute('class', 'parent-template-link');
          parentTemplateName.setAttribute('href', '/#/applications/'+previewData.id);

          parentTemplateLabel.textContent = cfg.labels.parentTemplate;
          parentTemplateName.textContent = previewData.id;
          parentTemplateFrag.appendChild(parentTemplateLabel);
          parentTemplateFrag.appendChild(parentTemplateLink);
          parentTemplateLink.appendChild(parentTemplateName);
          previewParentTemplate.appendChild(parentTemplateFrag);
      
          var previewLifecycleState = _.el('p.' + cfg.className.previewLifecycleState);
          previewLifecycleState.textContent = cfg.labels.lifecycleState + previewData.lifecycleState;

          var previewEnabledState = _.el('p.' + cfg.className.previewEnabledState);
          previewEnabledState.textContent = cfg.labels.enabledState + previewData.enabledState;

          frag.appendChild(previewTags);
          frag.appendChild(previewParentTemplate); 
          frag.appendChild(previewLifecycleState);
          frag.appendChild(previewEnabledState);
            

          // return frag;
        }
        return frag;
        
      };

      finder.createItemContent = function createItemContent(cfg, item) {
        var data = item.children || cfg.data;
        var frag = document.createDocumentFragment();
        var label = _.el('span');

        var iconPrepend = _.el('i');
        var iconAppend = _.el('i');
        var appendClasses = ['fa'];

        // text label
        label.appendChild(iconPrepend);
        label.appendChild(_.text(item.label));
        frag.appendChild(label);

        // appended icon
        if (data) {
          // console.log('fa data', item);
          appendClasses.push('fa-caret-right');
          _.addClass(iconAppend, appendClasses);
        }
      
        frag.appendChild(iconAppend);

        return frag;
      };

      /**
      * @param  {object} cfg config object
      * @param  {object} item data
      */

      //  copy of createItem
      finder.createPreviewItem = function createPreviewItem(previewData, cfg) {
        // console.log('createPreviewItem(previewData)',previewData);
        // console.log('createPreviewItem(cfg)',cfg);
        var frag = document.createDocumentFragment();
        var button = _.el('button.' + cfg.className.togglePreview);

        button.appendChild(document.createTextNode('show/hide'));
        button.setAttribute('id', 'toggle-preview-btn');

        var createPreviewItemContent = cfg.createPreviewItemContent || finder.createPreviewItemContent;

        frag = createPreviewItemContent.call(null, previewData, cfg);

        return frag;

      };

      finder.createItem = function createItem(cfg, item) {
        var frag = document.createDocumentFragment();
        var liClassNames = [cfg.className.item];
        var li = _.el('li');
        var a = _.el('a');
        var createItemContent = cfg.createItemContent || finder.createItemContent;

        frag = createItemContent.call(null, cfg, item);
        a.appendChild(frag);

        a.href = '';
        a.setAttribute('tabindex', -1);
        if (item.url) {
          a.href = item.url;
          liClassNames.push(cfg.className.url);
        }
        if (item.className) {
          liClassNames.push(item.className);
        }
        if (item[cfg.childKey]) {
          liClassNames.push(cfg.className[cfg.childKey]);
        }
        _.addClass(li, liClassNames);
        li.appendChild(a);
        li.setAttribute('data-fjs-item', item[cfg.labelKey]);
        li._item = item;

        return li;
      };


      finder.createEndItem = function createItem(cfg, item) {
        console.log('\n\ncreateEndItem(item)',item);
        console.log('\n\ncreateEndItem(cfg)',cfg);
        var frag = document.createDocumentFragment();
        var liClassNames = [cfg.className.item];
        console.log('liClassNames',liClassNames);
        var li = _.el('li');
        var createEndItemContent = cfg.createEndItemContent || finder.createEndItemContent;

        console.log('item[cfg.attributesDataKey]',item[cfg.attributesDataKey]);
        // frag = createEndItemContent.call(null, cfg, item);

        li.appendChild(_.text(item.name));
        // li.appendChild(frag);
        // li.setAttr/ibute('data-fjs-item', item[cfg.childKey]);
        li._item = item;

        return li;
      };
    },{
      "./util": "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/util.js",
      "eventemitter3": "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/eventemitter3/index.js",
      "global/document": "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/global/document.js",
      "global/window": "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/global/window.js",
      "x-is-array": "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/x-is-array/index.js",
      "xtend": "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/xtend/immutable.js"
    }],
    "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/browser-resolve/empty.js": [function(require, module, exports) {

    }, {}],
    "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/eventemitter3/index.js": [function(require, module, exports) {
      "use strict";
      var has = Object.prototype.hasOwnProperty,
        prefix = "~";

      function Events() {}

      function EE(e, t, n) {
        this.fn = e, this.context = t, this.once = n || !1
      }

      function EventEmitter() {
        this._events = new Events, this._eventsCount = 0
      }
      Object.create && (Events.prototype = Object.create(null), (new Events).__proto__ || (prefix = !1)), EventEmitter.prototype.eventNames = function() {
        var e, t, n = [];
        if (0 === this._eventsCount) return n;
        for (t in e = this._events) has.call(e, t) && n.push(prefix ? t.slice(1) : t);
        return Object.getOwnPropertySymbols ? n.concat(Object.getOwnPropertySymbols(e)) : n
      }, EventEmitter.prototype.listeners = function(e, t) {
        var n = prefix ? prefix + e : e,
          r = this._events[n];
        if (t) return !!r;
        if (!r) return [];
        if (r.fn) return [r.fn];
        for (var s = 0, i = r.length, o = new Array(i); s < i; s++) o[s] = r[s].fn;
        return o
      }, EventEmitter.prototype.emit = function(e, t, n, r, s, i) {
        var o = prefix ? prefix + e : e;
        if (!this._events[o]) return !1;
        var v, f, h = this._events[o],
          c = arguments.length;
        if (h.fn) {
          switch (h.once && this.removeListener(e, h.fn, void 0, !0), c) {
            case 1:
              return h.fn.call(h.context), !0;
            case 2:
              return h.fn.call(h.context, t), !0;
            case 3:
              return h.fn.call(h.context, t, n), !0;
            case 4:
              return h.fn.call(h.context, t, n, r), !0;
            case 5:
              return h.fn.call(h.context, t, n, r, s), !0;
            case 6:
              return h.fn.call(h.context, t, n, r, s, i), !0
          }
          for (f = 1, v = new Array(c - 1); f < c; f++) v[f - 1] = arguments[f];
          h.fn.apply(h.context, v)
        } else {
          var p, a = h.length;
          for (f = 0; f < a; f++) switch (h[f].once && this.removeListener(e, h[f].fn, void 0, !0), c) {
            case 1:
              h[f].fn.call(h[f].context);
              break;
            case 2:
              h[f].fn.call(h[f].context, t);
              break;
            case 3:
              h[f].fn.call(h[f].context, t, n);
              break;
            case 4:
              h[f].fn.call(h[f].context, t, n, r);
              break;
            default:
              if (!v)
                for (p = 1, v = new Array(c - 1); p < c; p++) v[p - 1] = arguments[p];
              h[f].fn.apply(h[f].context, v)
          }
        }
        return !0
      }, EventEmitter.prototype.on = function(e, t, n) {
        var r = new EE(t, n || this),
          s = prefix ? prefix + e : e;
        return this._events[s] ? this._events[s].fn ? this._events[s] = [this._events[s], r] : this._events[s].push(r) : (this._events[s] = r, this._eventsCount++), this
      }, EventEmitter.prototype.once = function(e, t, n) {
        var r = new EE(t, n || this, !0),
          s = prefix ? prefix + e : e;
        return this._events[s] ? this._events[s].fn ? this._events[s] = [this._events[s], r] : this._events[s].push(r) : (this._events[s] = r, this._eventsCount++), this
      }, EventEmitter.prototype.removeListener = function(e, t, n, r) {
        var s = prefix ? prefix + e : e;
        if (!this._events[s]) return this;
        if (!t) return 0 == --this._eventsCount ? this._events = new Events : delete this._events[s], this;
        var i = this._events[s];
        if (i.fn) i.fn !== t || r && !i.once || n && i.context !== n || (0 == --this._eventsCount ? this._events = new Events : delete this._events[s]);
        else {
          for (var o = 0, v = [], f = i.length; o < f; o++)(i[o].fn !== t || r && !i[o].once || n && i[o].context !== n) && v.push(i[o]);
          v.length ? this._events[s] = 1 === v.length ? v[0] : v : 0 == --this._eventsCount ? this._events = new Events : delete this._events[s]
        }
        return this
      }, EventEmitter.prototype.removeAllListeners = function(e) {
        var t;
        return e ? (t = prefix ? prefix + e : e, this._events[t] && (0 == --this._eventsCount ? this._events = new Events : delete this._events[t])) : (this._events = new Events, this._eventsCount = 0), this
      }, EventEmitter.prototype.off = EventEmitter.prototype.removeListener, EventEmitter.prototype.addListener = EventEmitter.prototype.on, EventEmitter.prototype.setMaxListeners = function() {
        return this
      }, EventEmitter.prefixed = prefix, EventEmitter.EventEmitter = EventEmitter, "undefined" != typeof module && (module.exports = EventEmitter);

    }, {}],
      "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/global/document.js": [function(require, module, exports) {
        (function(global) {
          var doccy, topLevel = "undefined" != typeof global ? global : "undefined" != typeof window ? window : {},
              minDoc = require("min-document");
          "undefined" != typeof document ? doccy = document : (doccy = topLevel["__GLOBAL_DOCUMENT_CACHE@4"]) || (doccy = topLevel["__GLOBAL_DOCUMENT_CACHE@4"] = minDoc), module.exports = doccy;

        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
      }, {
        "min-document": "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/browser-resolve/empty.js"
      }],
      "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/global/window.js": [function(require, module, exports) {
        (function(global) {
          var win;
          win = "undefined" != typeof window ? window : "undefined" != typeof global ? global : "undefined" != typeof self ? self : {}, module.exports = win;

        }).call(this, typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
      }, {}],
      "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/x-is-array/index.js": [function(require, module, exports) {
        var nativeIsArray = Array.isArray,
          toString = Object.prototype.toString;

        function isArray(r) {
          return "[object Array]" === toString.call(r)
        }
        module.exports = nativeIsArray || isArray;

      }, {}],
      "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/xtend/immutable.js": [function(require, module, exports) {
        module.exports = extend;
        var hasOwnProperty = Object.prototype.hasOwnProperty;

        function extend() {
          for (var r = {}, e = 0; e < arguments.length; e++) {
            var t = arguments[e];
            for (var n in t) hasOwnProperty.call(t, n) && (r[n] = t[n])
          }
          return r
        }

      }, {}],
      "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/util.js": [function(require, module, exports) {
        "use strict";
        var document = require("global/document"),
          isArray = require("x-is-array");

        function isElement(e) {
          try {
            return e instanceof Element
          } catch (r) {
            return !(!e || 1 !== e.nodeType)
          }
        }

        function el(e) {
          var r, s = [],
            n = e;
          return isElement(e) ? e : ((s = e.split(".")).length > 1 && (n = s[0]), addClass(r = document.createElement(n), s.slice(1)), r)
        }

        function frag() {
          return document.createDocumentFragment()
        }

        function text(e) {
          return document.createTextNode(e)
        }

        function remove(e) {
          return "remove" in e ? e.remove() : e.parentNode.removeChild(e), e
        }

        function closest(e, r) {
          for (var s = e; s;) {
            if (r(s)) return s;
            s = s.parentNode
          }
          return null
        }

        function addClass(e, r) {
          var s = r;
          return isArray(r) || (s = r.trim().split(/\s+/)), s.forEach(function(e, r) {
            e.className ? hasClass(e, r) || (e.classList ? e.classList.add(r) : e.className += " " + r) : e.className = r
          }.bind(null, e)), e
        }

        function removeClass(e, r) {
          var s = r;
          return isArray(r) || (s = r.trim().split(/\s+/)), s.forEach(function(e, r) {
            var s;
            e.classList ? e.classList.remove(r) : (s = new RegExp("(?:^|\s)" + r + "(?!\S)", "g"), e.className = e.className.replace(s, "").trim())
          }.bind(null, e)), e
        }

        function hasClass(e, r) {
          return !!(e && "className" in e) && -1 !== e.className.split(/\s+/).indexOf(r)
        }

        function nextSiblings(e) {
          for (var r = e.nextSibling, s = []; r;) s.push(r), r = r.nextSibling;
          return s
        }

        function previousSiblings(e) {
          for (var r = e.previousSibling, s = []; r;) s.push(r), r = r.previousSibling;
          return s
        }

        function stop(e) {
          return e.stopPropagation(), e.preventDefault(), e
        }

        function first(e, r) {
          return e.querySelector(r)
        }

        function append(e, r) {
          var s = frag();
          return (isArray(r) ? r : [r]).forEach(s.appendChild.bind(s)), e.appendChild(s), e
        }
        module.exports = {
          el: el,
          frag: frag,
          text: text,
          closest: closest,
          addClass: addClass,
          removeClass: removeClass,
          hasClass: hasClass,
          nextSiblings: nextSiblings,
          previousSiblings: previousSiblings,
          remove: remove,
          stop: stop,
          first: first,
          append: append
        };

      }, 
      {
        "global/document": "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/global/document.js",
        "x-is-array": "/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/node_modules/x-is-array/index.js"
      }
    ]
  }, {}, ["/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/index.js"])("/Users/carlitoa/Library/Mobile Documents/com~apple~CloudDocs/Shared Sites/columnview/working/column-view/index.js")
});